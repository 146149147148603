<template>
  <v-container fluid>
    <v-row
      v-if="selectedGW"
      class="form-padding">
      <v-col
        cols="12"
        class="bg-white rounded elevation-3">
        <h3> GW Product </h3>
        <v-row class="mt-2">
          <v-col cols="auto">
            <gw-product-image
              :src="selectedGW.photoUrls[0]"
              class="rounded" />
          </v-col>
          <v-col>
            <v-row>
              <v-col cols="12">
                <h4 class="product-name subtitle-1">
                  ชื่อสินค้า : <strong class="font-weight-bold">{{ selectedGW.name }}</strong>
                </h4>
                <v-row class="product-attribute">
                  <v-col class="product-attribute-left">
                    <p>
                      รุ่นสินค้า: {{ selectedGW.model }}
                    </p>
                  </v-col>
                  <v-col class="product-attribute-left">
                    <p>
                      หมวดหมู่สินค้า: {{ mapCategories(selectedGW.categories) }}
                    </p>
                  </v-col>
                  <v-col class="product-attribute-left">
                    <p>
                      รหัสนามแฝง: {{ selectedGW.aliasId }}
                    </p>
                  </v-col>
                </v-row>
                <v-row class="product-attribute">
                  <v-col class="product-attribute-left">
                    <p>
                      แบรนด์: {{ selectedGW.brand }}
                    </p>
                  </v-col>
                  <v-col class="product-attribute-right">
                    <p>
                      แท็ก:
                      <v-chip
                        v-for="(tag, i) in selectedGW.tags"
                        :key="`${selectedGW.id}-tag-${tag}-${i}`"
                        class="ma-1"
                        text-color="white"
                        color="#c379aa"
                        small>
                        {{ tag.name || tag }}
                      </v-chip>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row class="product-skus">
              <v-col cols="12">
                <v-simple-table class="skus-table pa-3 pt-0">
                  <thead>
                    <tr>
                      <th
                        v-for="(headSku, i) in skusDetails"
                        :key="`skusDetails-${i}`"
                        class="text-center">
                        {{ headSku }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(sku, i) in selectedGW.skus"
                      :key="`skus-${i}`"
                      class="text-center text-capitalize">
                      <td>
                        {{ sku.code }}
                      </td>
                      <td align="center">
                        {{ sku.size.name }}
                      </td>
                      <td align="center">
                        {{ sku.color.name }}
                      </td>
                      <td align="center">
                        {{ sku.price }}
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="form-padding">
      <v-col
        cols="12"
        class="bg-white rounded elevation-3">
        <h3> LINE MyShop Products </h3>
        <my-shop-products
          :brand="brand"
          @on-select="onSelectMyShop($event)" />
      </v-col>
    </v-row>

    <div ref="my-shop-form">
      <v-row
        v-if="selectedMyShop"
        class="form-padding mt-3">
        <v-col
          cols="12"
          class="bg-white rounded elevation-3">
          <h3> Mapping Form </h3>
          <my-shop-form
            v-model="formData"
            :gw-products="selectedGW"
            :my-shop-products="selectedMyShop"
            @on-submit="onSubmit()" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SizeValues from '@/assets/js/SizeValues'
import LineMyShopProvider from '@/resources/LineMyShopProvider'
import ProductProvider from '@/resources/ProductProvider'
import MyShopProducts from '../components/MyShopProducts.vue'
import MyShopForm from '../components/MyShopForm.vue'

const LineMyShopService = new LineMyShopProvider()
const ProductService = new ProductProvider()

export default {
  components: {
    MyShopProducts,
    MyShopForm
  },
  data () {
    return {
      loading: false,
      selectedGW: null,
      selectedMyShop: null,
      formData: [],
      createMapping: [],
      updateMapping: [],
      deleteMapping: [],
      skusDetails: [
        'code',
        'size',
        'color',
        'price'
      ]
    }
  },
  computed: {
    brand () {
      return this.selectedGW?.brand || null
    }
  },
  mounted () {
    this.getLineMyShopMapping(this.$route.params?.id)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading',
      setModal: 'Components/setModal'
    }),
    mapCategories (data) {
      return data.map((r) => r).join(', ')
    },
    async getLineMyShopMapping (id) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'Processing...'
        })

        const { data: gwProduct } = await ProductService.getProductById(id)
        this.selectedGW = {
          ...gwProduct,
          skus: gwProduct.skus.sort((a, b) => SizeValues(a.size.name) - SizeValues(b.size.name))
        }

        const { data: mappedProduct } = await LineMyShopService.getProducts({ product: id })

        if (mappedProduct?.results?.length) {
          const myShopProductId = mappedProduct?.results[0]?.productId || null

          const { data: myShopProduct } = await LineMyShopService.getLineMyShopProducts({
            ids: [myShopProductId],
            brand: this.selectedGW.brand
          })
          this.selectedMyShop = myShopProduct?.data[0] || null

          this.formData = this.selectedGW.skus.map((s) => {
            const foundSku = mappedProduct.results.find((r) => r.sku.id === s.id)

            if (foundSku) {
              return {
                ...foundSku,
                product: foundSku?.product?.id || null,
                sku: foundSku?.sku?.id || null
              }
            }

            return {
              product: this.selectedGW.id,
              productId: null,
              productCode: this.selectedGW.model,
              name: this.selectedGW.name,
              sku: s.id,
              variantSku: '',
              inventoryId: null
            }
          })

          this.formData = this.formData.sort((a, b) => {
            const skuA = this.selectedGW.skus.find((s) => s.id === a.sku)
            const skuB = this.selectedGW.skus.find((s) => s.id === b.sku)

            return SizeValues(skuA.size.name) - SizeValues(skuB.size.name)
          })
        } else {
          this.formData = this.selectedGW.skus.map((s) => ({
            product: this.selectedGW.id,
            productId: null,
            productCode: this.selectedGW.model,
            name: this.selectedGW.name,
            sku: s.id,
            variantSku: '',
            inventoryId: null
          }))
        }
      } catch (error) {
        console.error('getLineMyShopMapping', error)
        this.setSnackbar({
          value: true,
          message: `[GET-MAPPING-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    onSelectMyShop (product) {
      this.selectedMyShop = product

      this.formData = this.selectedGW.skus.map((s) => ({
        product: this.selectedGW.id,
        productId: this.selectedMyShop.id,
        productCode: this.selectedGW.model,
        name: this.selectedGW.name,
        sku: s.id,
        variantSku: '',
        inventoryId: null
      }))

      setTimeout(() => {
        this.$refs['my-shop-form'].scrollIntoView({
          behavior: 'smooth'
        })
      }, 500)
    },
    async onSubmit () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'Processing...'
        })

        this.createMapping = this.formData.filter((f) => !f?.id && f.inventoryId)
        this.updateMapping = this.formData.filter((f) => f?.id)
        this.deleteMapping = this.formData.filter((f) => f?.id && !f.inventoryId)

        if (this.createMapping.length) {
          const promises = this.createMapping.map((c) => LineMyShopService.createProductsMapping(c))
          await Promise.all(promises)
        }

        if (this.updateMapping.length) {
          const promises = this.updateMapping.map((u) => LineMyShopService.updateProductsMapping(u))
          await Promise.all(promises)
        }

        if (this.deleteMapping.length) {
          const promises = this.deleteMapping.map((d) => LineMyShopService.deleteProductsMapping(d.id))
          await Promise.all(promises)
        }

        this.setSnackbar({
          value: true,
          message: 'Update product mapped success.',
          type: 'success'
        })

        this.$router.back()
      } catch (error) {
        console.error('onSubmit', error)
        this.setSnackbar({
          value: true,
          message: `[MAPPING-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    }
  }
}
</script>

<style scoped>
.bg-white {
  background-color: #fff;
}
.form-padding {
  padding: 10px 15px;
}
.skus-table th {
  text-transform: capitalize;
}
h4.product-name {
  margin-bottom: .5rem;
}
.text-capitalize {
  text-transform: capitalize;
}
</style>
