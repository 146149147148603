<template>
  <v-form
    ref="my-shop-form"
    @submit.prevent="onSubmit()">
    <v-data-table
      :headers="headers"
      :items="formData"
      :items-per-page="-1"
      hide-default-footer>
      <template #[`item.code`]="{ item }">
        <span class="text-capitalize">
          {{ getSkuCode(item.sku) }}
        </span>
      </template>
      <template #[`item.color`]="{ item }">
        <span class="text-capitalize">
          {{ getSkuColor(item.sku) }}
        </span>
      </template>
      <template #[`item.size`]="{ item }">
        <span class="text-capitalize">
          {{ getSkuSize(item.sku) }}
        </span>
      </template>
      <template #[`item.price`]="{ item }">
        <span class="text-capitalize">
          {{ getSkuPrice(item.sku) }}
        </span>
      </template>
      <template #[`item.variantSku`]="{ index }">
        <v-select
          v-model="formData[index].variantSku"
          :items="myShopProducts.variants"
          item-text="sku"
          item-value="sku"
          dense
          outlined
          hide-details>
        </v-select>
      </template>
      <template #[`item.actions`]="{ index }">
        <v-btn
          color="error"
          small
          icon
          @click="deleteProductMapping(index)">
          <v-icon small>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <div class="d-flex justify-center mt-5 mb-3">
      <v-btn
        type="submit"
        color="secondary">
        Submit
      </v-btn>
    </div>
  </v-form>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    gwProducts: {
      type: Object,
      default: null
    },
    myShopProducts: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      headers: [
        {
          text: 'Code',
          value: 'code',
          align: 'center',
          sortable: false
        },
        {
          text: 'Color',
          value: 'color',
          align: 'center',
          sortable: false
        },
        {
          text: 'Size',
          value: 'size',
          align: 'center',
          sortable: false
        },
        {
          text: 'Price',
          value: 'price',
          align: 'right',
          sortable: false
        },
        {
          text: 'MyShop Product',
          value: 'variantSku',
          align: 'center',
          width: '250px',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ]
    }
  },
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    getSkuCode (skuId) {
      const foundSku = this.gwProducts.skus.find((s) => s.id === skuId)

      if (!foundSku) {
        return '-'
      }

      return foundSku.code
    },
    getSkuColor (skuId) {
      const foundSku = this.gwProducts.skus.find((s) => s.id === skuId)

      if (!foundSku) {
        return '-'
      }

      return foundSku.color.name
    },
    getSkuSize (skuId) {
      const foundSku = this.gwProducts.skus.find((s) => s.id === skuId)

      if (!foundSku) {
        return '-'
      }

      return foundSku.size.name
    },
    getSkuPrice (skuId) {
      const foundSku = this.gwProducts.skus.find((s) => s.id === skuId)

      if (!foundSku) {
        return '-'
      }

      return foundSku.price
    },
    deleteProductMapping (index) {
      this.formData[index].variantSku = ''
      this.formData[index].inventoryId = null
    },
    onSubmit () {
      const valid = this.$refs['my-shop-form'].validate()

      if (valid) {
        this.formData = this.formData.map((f) => {
          const variant = this.myShopProducts.variants.find((v) => v.sku === f.variantSku)

          return {
            ...f,
            productId: this.myShopProducts.id,
            variantSku: variant?.sku || '',
            inventoryId: variant?.inventoryId || null
          }
        })

        this.$emit('on-submit')
      }
    }
  }
}
</script>

<style scoped>
.text-capitalize {
  text-transform: capitalize;
}
</style>
