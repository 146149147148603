<template>
  <v-row justify="end">
    <v-col
      cols="12"
      md="4">
      <search-box
        v-model="query.ids"
        placeholder="MyShop Product ID"
        :disabled="loading"
        class="mt-2"
        @on-search="getProducts()" />
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="products"
        :loading="loading"
        :items-per-page="10"
        hide-default-footer>
        <template #[`item.image`]="{ item }">
          <gw-product-image
            :src="item.imageUrl"
            class="rounded" />
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="!selectedProduct || item.id !== selectedProduct.id"
            :loading="loading"
            :disabled="!!selectedProduct"
            color="secondary"
            small
            @click="onSelect(item)">
            Select
          </v-btn>
          <v-btn
            v-else
            :loading="loading"
            color="error"
            small
            @click="onDeselect()">
            Cancel
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import LineMyShopProvider from '@/resources/LineMyShopProvider'
import SearchBox from '@/components/SearchBox.vue'
import { mapActions } from 'vuex'

const LineMyShopService = new LineMyShopProvider()

export default {
  components: { SearchBox },
  props: {
    brand: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      products: [],
      query: {
        ids: ''
      },
      headers: [
        {
          text: 'Image',
          value: 'image',
          align: 'center',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
          align: 'start',
          sortable: false
        },
        {
          text: 'Model',
          value: 'code',
          align: 'center',
          sortable: false
        },
        {
          text: 'Brand',
          value: 'brand',
          align: 'center',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false
        }
      ],
      selectedProduct: null
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar'
    }),
    async getProducts () {
      try {
        this.loading = true

        const { data } = await LineMyShopService.getLineMyShopProducts({
          ids: [this.query.ids] || [],
          brand: this.brand
        })
        this.products = data.data
      } catch (error) {
        console.error('getProducts', error)
        this.setSnackbar({
          value: true,
          message: `[GET-MYSHOP-PRODUCTS-ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    onSelect (product) {
      this.selectedProduct = product
      return this.$emit('on-select', product)
    },
    onDeselect () {
      this.selectedProduct = null
      return this.$emit('on-select', null)
    }
  }
}
</script>

<style scoped>

</style>
