var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"my-shop-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.formData,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.getSkuCode(item.sku))+" ")])]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.getSkuColor(item.sku))+" ")])]}},{key:"item.size",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.getSkuSize(item.sku))+" ")])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.getSkuPrice(item.sku))+" ")])]}},{key:"item.variantSku",fn:function(ref){
var index = ref.index;
return [_c('v-select',{attrs:{"items":_vm.myShopProducts.variants,"item-text":"sku","item-value":"sku","dense":"","outlined":"","hide-details":""},model:{value:(_vm.formData[index].variantSku),callback:function ($$v) {_vm.$set(_vm.formData[index], "variantSku", $$v)},expression:"formData[index].variantSku"}})]}},{key:"item.actions",fn:function(ref){
var index = ref.index;
return [_c('v-btn',{attrs:{"color":"error","small":"","icon":""},on:{"click":function($event){return _vm.deleteProductMapping(index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex justify-center mt-5 mb-3"},[_c('v-btn',{attrs:{"type":"submit","color":"secondary"}},[_vm._v(" Submit ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }